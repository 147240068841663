
html,
body {
    font-family: Inter, serif !important;
    font-size: 14px;
    width: 100%;
    height: 100%;
    color: #141416;
}

#root {
    height: -webkit-fill-available;
    max-width: 820px;
    margin: auto;
    position: relative;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: url(assets/fonts/inters/Inter-Thin.ttf);
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: url(assets/fonts/inters/Inter-ExtraLight.ttf);
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url(assets/fonts/inters/Inter-Light.ttf);
}


@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(assets/fonts/inters/Inter-Regular.ttf);
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url(assets/fonts/inters/Inter-Medium.ttf);
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url(assets/fonts/inters/Inter-SemiBold.ttf);
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url(assets/fonts/inters/Inter-Bold.ttf);
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url(assets/fonts/inters/Inter-ExtraBold.ttf);
}
